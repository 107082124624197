import clsx from "clsx";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useBirdBattle } from "../../../store/useBirdBattle";
import BaseBtn from "../Components/BaseBtn";
// import { toast } from "react-toastify";
import { isDesktop, isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { Slide, toast, ToastContainer } from "react-toastify";
import useWebSocket, { ReadyState } from "react-use-websocket";
import ModalContainer from "../../../components/common/ModalContainer";
import { useAccountV2 } from "../../../components/Hooks/useRegisterV2";
import { navPaths } from "../../../constants/navbar.constants";
import GameCountDown from "../Components/DeployBird/CountDown";
import GameMap10x10 from "../Components/DeployBird/GameMap10x10";
import CreatematchModal from "../Components/Modal/CreateMatchModal";
import { sleep } from "../../../utils/time";
import PickTurn from "./PickTurn";
// import { telegramID } from "../../../utils/helper";
import PublicMatchMaking from "../Components/MatchMaking/PublicMatchMaking";

// {"data":null,"error":"websocket: close 1000 (normal)"}

const DeoployBird = () => {
  const navigate = useNavigate();

  const tele = window.Telegram.WebApp;
  const telegramID = tele?.initData?.replaceAll("&", "%26");

  tele.BackButton.show();
  tele.BackButton.onClick(() => handleBackButton());

  const gameMapRef = useRef<{
    fireFromParent: any;
    autoArrangeFromParent: any;
  }>(null);

  const match = useBirdBattle((state: any) => state.match);
  const room = useBirdBattle((state: any) => state.room);
  const lineup = useBirdBattle((state: any) => state.lineup);

  const [ready, setReady] = useState<boolean>(false);

  const [matchMaking, setMatchMaking] = useState<boolean>(true);

  const WEBSOCKET_URL = process.env.REACT_APP_SOCKET_URL;
  const SOCKET_URL_MATCH_MAKING = `${WEBSOCKET_URL}/battle-seed/find-match?telegram_data=${telegramID}`;
  const SOCKET_URL = `${WEBSOCKET_URL}/battle-seed/build-lineup?room_id=${room?.id}&telegram_data=${telegramID}&match_id=${match?.id}`;
  const SOCKET_URL_ATTACK = `${WEBSOCKET_URL}/battle-seed/attack?room_id=${room?.id}&telegram_data=${telegramID}&match_id=${match?.id}`;

  const [socketUrl, setSocketUrl] = useState(SOCKET_URL_MATCH_MAKING);

  const handleBackButton = () => {
    if (matchMaking || socketUrl === SOCKET_URL) {
      updateRandomHitExclude([]);
      updateRoom(null);
      updateMatch(null);
      navigate(navPaths.BATTLE_ONLINE);
      return;
    }
    navigate(navPaths.DEPLOY_BIRD);
    setShowRetreatModal(true);
  };

  const handleChangeSocketBuildLineupUrl = (
    room: { id: any },
    match: { id: any },
  ) => {
    const SOCKET_URL_BUILD_LINEUP = `${WEBSOCKET_URL}/battle-seed/build-lineup?room_id=${room?.id}&telegram_data=${telegramID}&match_id=${match?.id}`;
    setSocketUrl(SOCKET_URL_BUILD_LINEUP);
  };
  const handleChangeSocketAttackUrl = () => setSocketUrl(SOCKET_URL_ATTACK);
  const handleCloseSocket = () => setSocketUrl("wss://demos.kaazing.com/echo");

  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, {
    shouldReconnect: () => true,
    // heartbeat: {
    //   timeout: 3600000, // 1 hour
    // },
  });

  const handleSendMessage = useCallback(
    (message: string) => {
      sendMessage(message);
    },
    [sendMessage],
  );

  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];

  const lastMessageData = useMemo(() => {
    if (lastMessage?.data) {
      return JSON.parse(lastMessage?.data);
    } else {
      return null;
    }
  }, [lastMessage?.data]);
  console.log("DeoployBird lastMessageData", lastMessageData);

  console.log("DeoployBird connectionStatus", connectionStatus);

  const [turn, setTurn] = useState<number>(0);

  const { accountV2 } = useAccountV2();

  const youReady =
    accountV2?.data?.id === room?.red_user_id
      ? lastMessageData?.RedReady
      : lastMessageData?.BlueReady;

  const allPlayerReady =
    lastMessageData?.RedReady && lastMessageData?.BlueReady;

  const [showPickTurn, setShowPickTurn] = useState<number>(0);

  useEffect(() => {
    if (allPlayerReady) {
      handleChangeSocketAttackUrl();
      setShowPickTurn(1);
      // gameCountDownRef.current?.restartFromParent();
      // setReady(true);
      setTurn((prev) => prev + 1);
      // handleChangeSocketAttackUrl();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPlayerReady]);

  useEffect(() => {
    if (showPickTurn === 2) {
      gameCountDownRef.current?.restartFromParent();
      setReady(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPickTurn]);

  const yourTurn = useBirdBattle((state: any) => state.yourTurn);
  const updateYourTurn = useBirdBattle((state: any) => state.updateYourTurn);

  useEffect(() => {
    if (accountV2?.data?.id && lastMessageData?.is_turn) {
      updateYourTurn(accountV2?.data?.id === lastMessageData?.is_turn);
    }
  }, [accountV2?.data?.id, lastMessageData?.is_turn, updateYourTurn]);

  // Endgame
  const endgame = useBirdBattle((state: any) => state.endgame);
  const updateEndgame = useBirdBattle((state: any) => state.updateEndgame);
  const updateRandomHitExclude = useBirdBattle(
    (state: any) => state.updateRandomHitExclude,
  );
  const updateRoom = useBirdBattle((state: any) => state.updateRoom);
  const updateMatch = useBirdBattle((state: any) => state.updateMatch);

  useEffect(() => {
    if (lastMessageData?.winner && lastMessageData?.loser) {
      updateEndgame({
        winner: lastMessageData?.winner,
        loser: lastMessageData?.loser,
        ...lastMessageData,
      });
      handleCloseSocket();
      updateRandomHitExclude([]);
      updateRoom(null);
      updateMatch(null);
      navigate(navPaths.END_GAME);
    }
  }, [
    lastMessageData,
    lastMessageData?.loser,
    lastMessageData?.winner,
    navigate,
    updateEndgame,
    updateMatch,
    updateRandomHitExclude,
    updateRoom,
  ]);

  useEffect(() => {
    if (
      socketUrl === SOCKET_URL &&
      lastMessageData?.error?.includes("left_the_room")
    ) {
      handleOpponentRetreat();
    }

    if (
      lastMessageData?.error?.includes("match is over") ||
      lastMessageData?.error?.includes("websocket: close 1005 (no status)") ||
      lastMessageData?.error?.includes("websocket: close 1001 (going away)")
    ) {
      if (
        lastMessageData?.error?.includes("websocket: close 1005 (no status)") ||
        lastMessageData?.error?.includes("websocket: close 1001 (going away)")
      ) {
        updateEndgame({
          ...endgame,
          opponentRetreat: true,
        });
      } else {
        updateEndgame({
          ...endgame,
          opponentRetreat: false,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    lastMessageData?.error,
    lastMessageData?.loser,
    lastMessageData?.winner,
    navigate,
    updateEndgame,
    updateMatch,
    updateRandomHitExclude,
    updateRoom,
  ]);

  const handleOpponentRetreat = async () => {
    toast.info("The opponent has left the match", {
      style: { maxWidth: 337, height: 40, borderRadius: 8 },
      autoClose: 1000,
      toastId: "opponent-left",
    });
    await sleep(1000);
    updateRandomHitExclude([]);
    updateRoom(null);
    updateMatch(null);
    navigate(navPaths.BATTLE_ONLINE);
  };

  // const randomHitExclude = useBirdBattle(
  //   (state: any) => state.randomHitExclude,
  // );

  const countTurn = useRef(0);
  const firstHit = useRef(true);

  useEffect(() => {
    if (lastMessageData?.attack) {
      const attackResult = Object.values(lastMessageData?.attack)[0];
      if (attackResult && yourTurn) {
        if (firstHit.current) {
          toast.info("Hit! Keep shooting!", {
            style: { maxWidth: 337, height: 40, borderRadius: 8 },
            autoClose: 500,
            toastId: "you-hit",
          });
        }
        firstHit.current = false;
        setSkipCount(0);
      }
      if (!attackResult) {
        if (yourTurn) {
          toast.info("You missed!", {
            style: { maxWidth: 337, height: 40, borderRadius: 8 },
            autoClose: 500,
            toastId: "you-missed",
          });
        }
        firstHit.current = true;
        countTurn.current = countTurn.current + 1;
        if (countTurn.current === 2) {
          setTurn((prev) => prev + 1);
          countTurn.current = 0;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastMessageData?.attack]);

  const [skipCount, setSkipCount] = useState<number>(0);

  useEffect(() => {
    if (skipCount === 3) {
      updateEndgame({
        winner: "",
        loser: accountV2?.data?.id,
        opponentRetreat: false,
      });
      handleCloseSocket();
      updateRandomHitExclude([]);
      updateRoom(null);
      updateMatch(null);
      navigate(navPaths.END_GAME);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skipCount]);

  const onCompleteTime = () => {
    // auto send a fail attack when countdown complete to end turn
    toast.dismiss({ id: "you-missed", containerId: "battle-seed" });

    if (yourTurn && socketUrl === SOCKET_URL_ATTACK) {
      if (lastMessageData?.attack) {
        const attackResult = Object.values(lastMessageData?.attack)[0];
        if (attackResult) {
          gameMapRef.current?.fireFromParent(-1);
          return;
        }
      }
      gameMapRef.current?.fireFromParent(-1);
      toast.info("Time's up! You missed your turn", {
        style: { maxWidth: 337, height: 40, borderRadius: 8 },
        autoClose: 1000,
        toastId: "time-up",
      });
      setSkipCount((prev) => prev + 1);
    }
    if (!youReady && socketUrl === SOCKET_URL) {
      handleSendMessage(
        JSON.stringify({
          lineup: lineup,
        }),
      );
      return;
    }
  };

  // const Loader = () => {
  //   return (
  //     <div className="absolute z-20 inset-0 backdrop-blur-md flex items-center justify-center">
  //       <div className="game-loader"></div>
  //     </div>
  //   );
  // };

  const [showRetreatModal, setShowRetreatModal] = useState<boolean>(false);
  const handleRetreat = () => {
    if (socketUrl === SOCKET_URL) {
      handleCloseSocket();
      updateRandomHitExclude([]);
      updateRoom(null);
      updateMatch(null);
      navigate(navPaths.BATTLE_ONLINE);
      return;
    }
    updateEndgame({
      winner: "",
      loser: accountV2?.data?.id,
      opponentRetreat: false,
    });
    handleCloseSocket();
    updateRandomHitExclude([]);
    updateRoom(null);
    updateMatch(null);
    navigate(navPaths.END_GAME);
  };

  const gameCountDownRef = useRef<{ restartFromParent: any }>(null);

  const opponentName =
    accountV2?.data?.id === room?.red_user_id
      ? room?.blue_user_name
      : room?.red_user_name;

  return (
    <>
      <ToastContainer
        position="top-left"
        closeOnClick
        transition={Slide}
        hideProgressBar
        limit={1}
        stacked
        className="top-3 max-w-[337px] left-[50%] -translate-x-[50%]"
        containerId="battle-seed"
      />
      <div
        className={clsx(
          "py-[20px] px-5 bg-[#F2FFE0] h-screen z-30 bg-cover bg-no-repeat bg-center font-baloo-paaji absolute inset-0 transition-all ease-linear",
          matchMaking ? "translate-y-0" : "translate-y-[100%]",
        )}
        style={{ backgroundImage: `url("/images/battle_bird/background.png")` }}
      >
        <PublicMatchMaking
          setMatchMaking={setMatchMaking}
          handleChangeSocketBuildLineupUrl={handleChangeSocketBuildLineupUrl}
          sendMessage={sendMessage}
          lastMessage={lastMessage}
          connectionStatus={connectionStatus}
          socketUrl={socketUrl}
          telegramID={telegramID}
        />
      </div>
      <PickTurn
        pickTurn={showPickTurn}
        setShowPickTurn={setShowPickTurn}
        data={lastMessageData}
        accountId={accountV2?.data?.id || ""}
      />
      {!matchMaking && (
        <div
          className="px-4 text-white bg-[#F2FFE0] h-screen relative z-30 bg-cover bg-no-repeat bg-center font-baloo-paaji flex flex-col items-center justify-center"
          style={{
            backgroundImage: `url("/images/battle_bird/background.png")`,
          }}
        >
          {/* {ready && !turn && <Loader />} */}
          <div className="w-screen h-screen backdrop-blur-md bg-[#00000066] absolute z-0 top-0 left-0"></div>
          <div className="z-10 flex flex-col items-center justify-center gap-1">
            <span className="font-baloo-paaji text-baloo-border font-black text-[40px] text-white uppercase">
              {turn >= 1 ? `Turn ${turn}` : "Lineup Build"}
            </span>
            <GameCountDown
              ref={gameCountDownRef}
              gameReady={ready}
              yourTurn={yourTurn}
              onCompleteTime={onCompleteTime}
              opponentName={opponentName}
            />
          </div>

          <GameMap10x10
            ref={gameMapRef}
            ready={ready}
            yourTurn={yourTurn}
            handleSendMessage={handleSendMessage}
            lastMessage={lastMessage}
            accountId={accountV2?.data?.id || ""}
          />

          <div
            className={clsx(
              "w-full z-10 px-[14px] flex items-center justify-center",
              ready ? "invisible" : "visible",
              isMobile ? "mt-5" : "mt-2",
            )}
          >
            <div className="flex items-center gap-2">
              {isMobile ? (
                <svg
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20 10.9746C20 11.2611 19.8958 11.4954 19.6875 11.6777L16.5625 14.4902C16.3802 14.6465 16.1719 14.7246 15.9375 14.7246C15.8073 14.7246 15.6771 14.6986 15.5469 14.6465C15.2083 14.4642 15.026 14.1777 15 13.7871V12.2246H11.25V15.9746H12.8125C13.2031 16.0007 13.4896 16.1829 13.6719 16.5215C13.8281 16.8861 13.776 17.2246 13.5156 17.5371L10.7031 20.6621C10.5208 20.8704 10.2734 20.9746 9.96094 20.9746C9.67448 20.9746 9.4401 20.8704 9.25781 20.6621L6.48438 17.5371C6.22396 17.2246 6.17188 16.8861 6.32812 16.5215C6.51042 16.1829 6.79688 16.0007 7.1875 15.9746H8.71094V12.2246H5V13.7871C4.97396 14.1777 4.79167 14.4642 4.45312 14.6465C4.32292 14.6986 4.19271 14.7246 4.0625 14.7246C3.82812 14.7246 3.61979 14.6465 3.4375 14.4902L0.3125 11.6777C0.104167 11.4954 0 11.2611 0 10.9746C0 10.6882 0.104167 10.4538 0.3125 10.2715L3.4375 7.45898C3.75 7.22461 4.08854 7.17253 4.45312 7.30273C4.79167 7.48503 4.97396 7.77148 5 8.16211V9.72461H8.71094V5.97461H7.1875C6.79688 5.94857 6.51042 5.76628 6.32812 5.42773C6.17188 5.06315 6.22396 4.72461 6.48438 4.41211L9.25781 1.28711C9.46615 1.07878 9.70052 0.974609 9.96094 0.974609C10.2474 0.974609 10.4818 1.07878 10.6641 1.28711L13.4766 4.41211C13.737 4.72461 13.7891 5.06315 13.6328 5.42773C13.4505 5.76628 13.1641 5.94857 12.7734 5.97461H11.2109V9.72461H15V8.16211C15.026 7.77148 15.2083 7.48503 15.5469 7.30273C15.9115 7.17253 16.25 7.22461 16.5625 7.45898L19.6875 10.2715C19.8958 10.4538 20 10.6882 20 10.9746Z"
                    fill="white"
                  />
                </svg>
              ) : (
                <div className="h-5" />
              )}
              <span className="font-semibold text-sm">
                {isMobile ? "Drag & drop your unit on the battlefield" : ""}
              </span>
            </div>
            {/* <div className="flex items-center gap-2">
          <svg
            width="21"
            height="24"
            viewBox="0 0 21 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="0.666667"
              y="8.65592"
              width="14.6667"
              height="14.6261"
              rx="2"
              fill="white"
              stroke="white"
              stroke-width="1.33333"
            />
            <path
              d="M10 3L13.9829 5.33869L14.0168 0.720007L10 3ZM13.5899 3.42628C15.1881 3.4674 16.3409 3.57275 17.1853 3.82559C18.0087 4.07215 18.5142 4.45118 18.853 5.04225C19.2066 5.65908 19.4021 6.54589 19.5011 7.85928C19.5997 9.16687 19.6 10.8395 19.6 13H20.4C20.4 10.8516 20.4003 9.14376 20.2989 7.79912C20.1979 6.46029 19.9935 5.42318 19.547 4.64441C19.0859 3.83988 18.3913 3.35162 17.4148 3.05921C16.4592 2.77307 15.2121 2.66776 13.6104 2.62655L13.5899 3.42628Z"
              fill="white"
            />
          </svg>
          <span className="font-medium text-sm">Tap to rotate</span>
        </div> */}
          </div>
          {ready && (
            <div
              className={clsx(
                "text-white z-30 absolute right-0 left-0 flex flex-col items-center justify-center",
                isMobile ? "bottom-[10%]" : "bottom-[5%]",
              )}
            >
              <span className="font-baloo-paaji text-baloo-border font-bold text-[24px]">
                {yourTurn && "Your turn to attack"}
              </span>

              {!yourTurn && (
                <div className="flex items-center">
                  <span className="font-baloo-paaji missed-turn font-bold text-[24px] capitalize">
                    {opponentName}
                  </span>
                  <span className="font-baloo-paaji missed-turn font-bold text-[24px]">
                    's turn to attack
                  </span>
                </div>
              )}
              <span className="text-sm text-center">
                If you skip <span className="missed-turn">3</span> turns in a
                row, you will lose
              </span>
              <span className="text-sm">
                Number of turns skipped:{" "}
                <span className="missed-turn">{skipCount}</span>
              </span>
            </div>
          )}
          <div
            className={clsx(
              "z-10 flex items-center w-full gap-4",
              ready ? "invisible" : "visible",
              isMobile ? "mt-5" : "mt-3 -mb-1",
            )}
          >
            <button
              onClick={() => {}}
              className={clsx(
                "text-[#fff] text-[24px] w-full font-bold uppercase ",
                "border-[2px] border-[#0B8B00] bg-[#00000033] shadow-[0_3.5px_0.5px_#0B8B00] rounded-xl",
                "active:translate-y-[3.5px] active:shadow-none",
              )}
            >
              <div
                onClick={() => {
                  gameMapRef.current?.autoArrangeFromParent();
                }}
                className={clsx(
                  "border-2 border-[#78ED4F] rounded-[11px] font-baloo-paaji text-baloo-border",
                  isMobile ? "py-[6px]" : "py-[1px]",
                )}
              >
                Auto
              </div>
            </button>
            <BaseBtn
              className={isMobile ? "py-[6px]" : "py-[1px]"}
              onClick={() =>
                handleSendMessage(
                  JSON.stringify({
                    lineup: lineup,
                  }),
                )
              }
            >
              Ready
            </BaseBtn>
          </div>
          <div
            className={clsx(
              "mt-3 text-[#FFE500] flex items-center justify-center z-10 w-full",
              (lastMessageData?.RedReady || lastMessageData?.BlueReady) &&
                turn < 1
                ? "visible"
                : "invisible",
              isDesktop && "mb-1",
            )}
          >
            {youReady
              ? "Waiting for opponents..."
              : "The opponent is waiting for you."}
          </div>
        </div>
      )}

      <ModalContainer>
        {showRetreatModal && (
          <CreatematchModal
            retreat={handleRetreat}
            handleClose={() => setShowRetreatModal(false)}
            title={"Retreat from game"}
            desciption={
              "If you leave the match, you will lose both the game and your money. Are you sure you want to exit?"
            }
            type="retreat"
          />
        )}
      </ModalContainer>
    </>
  );
};

export default DeoployBird;
