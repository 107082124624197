import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useStopwatch } from "react-timer-hook";
import { navPaths } from "../../../../constants/navbar.constants";
import { toast } from "react-toastify";
import { sleep } from "../../../../utils/time";

const StopWatch = () => {
  const navigate = useNavigate();
  const { seconds, minutes } = useStopwatch({ autoStart: true });

  const reMatch = async () => {
    toast.info("No one has matched with you yet. Please try searching again", {
      style: { maxWidth: 337, height: 60, borderRadius: 8 },
      autoClose: 2000,
      toastId: "noone-match",
    });
    await sleep(2000);
    navigate(navPaths.BATTLE_ONLINE);
  };

  useEffect(() => {
    if (seconds === 59) {
      reMatch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seconds]);

  if (seconds < 59) {
    return (
      <span className="font-baloo-paaji font-bold text-[24px] drop-shadow-[0_2px_2px_#053C00] text-white mt-2">
        {minutes < 10 ? "0" + minutes : minutes}:
        {seconds < 10 ? "0" + seconds : seconds}
      </span>
    );
  } else {
    return null;
  }
};

export default StopWatch;
