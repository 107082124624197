import { Suspense, useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import { router } from "./config/router";
import { ThemeProvider } from "@mui/material";
import { theme } from "./styles/theme";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "./config/queryClient";
import { Slide, ToastContainer } from "react-toastify";
import { useAppTime } from "./store/appTimeStore";
import { TonConnectUIProvider } from "@tonconnect/ui-react";
import LoadingScreen from "./modules/Global/Pages/LoadingScreen";

declare global {
  interface Window {
    Telegram: any;
    telegramAnalytics: any;
  }
}

function App() {
  useEffect(() => {
    window.Telegram.WebApp.ready();

    window?.telegramAnalytics?.init({
      token: process.env.REACT_APP_TELEGRAM_ANALYTICS_TOKEN,
      appName: process.env.REACT_APP_TELEGRAM_APP_NAME,
    });
  }, []);

  const getManifestUrl = () => {
    return new URL("tonconnect-manifest.json", window.location.href).toString();
  };

  useAppTime.getState().startTicking();

  return (
    <Suspense fallback={<LoadingScreen />}>
      <TonConnectUIProvider manifestUrl={getManifestUrl()}>
        <ThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <ToastContainer
              transition={Slide}
              position="top-left"
              hideProgressBar
              closeOnClick
              autoClose={2000}
              limit={1}
              stacked
              className="top-3 h-10  left-[50%] rounded-lg -translate-x-[50%]"
              style={{ height: 40, width: "fit-content" }}
            />
            <RouterProvider router={router}></RouterProvider>
          </QueryClientProvider>
        </ThemeProvider>
      </TonConnectUIProvider>
    </Suspense>
  );
}

export default App;
