import { Navigate, Outlet, useLocation } from "react-router-dom";
import { navPaths } from "../../constants/navbar.constants";
import { Suspense, useMemo } from "react";
import { ResponseCode } from "../../constants/response";
import LoadingScreen from "../../modules/Global/Pages/LoadingScreen";
import { useAccountBalance } from "../../modules/Home/Hooks/useGetAcountBalance";
import { useAccountV2 } from "../Hooks/useRegisterV2";
import { useActiveStore } from "../../store/activeStore";

const AuthLayout = () => {
  const location = useLocation();
  const tele = window.Telegram.WebApp;
  try {
    tele.setHeaderColor("#F7FFEB");
  } catch (err) {
    console.debug(err);
  }
  tele.expand();

  const setActiveStatus = useActiveStore((state: any) => state.setActiveStatus);
  const overflow = 100;
  document.body.style.overflowY = "hidden";
  document.body.style.marginTop = `${overflow}px`;
  document.body.style.height = window.innerHeight + overflow + "px";
  document.body.style.paddingBottom = `${overflow}px`;
  window.scrollTo(0, overflow);

  const { accountV2, isLoading } = useAccountV2();
  const { isLoading: isBalanceLoading } = useAccountBalance();

  const AccountActiveStatus = useMemo(() => {
    if (!accountV2?.data || !accountV2?.data.id) {
      return;
    }

    return accountV2.data?.status;
  }, [accountV2]);

  const statusCode = accountV2?.status ?? 0;

  if (isLoading || isBalanceLoading) {
    return <LoadingScreen />;
  }

  // navigate to inactive page if account is inactive
  if (
    accountV2?.data && accountV2.data.id &&
    statusCode !== ResponseCode.NOT_FOUND &&
    statusCode !== ResponseCode.SERVER_ERR &&
    statusCode !== ResponseCode.UNAUTHORIZED &&
    !AccountActiveStatus?.active &&
    location.pathname !== navPaths.INACTIVE
  ) {
    setActiveStatus(AccountActiveStatus);
    return <Navigate to={navPaths.INACTIVE} />;
  }

  // navigate to reload page if has error
  if (
    (statusCode === ResponseCode.SERVER_ERR ||
      statusCode === ResponseCode.SERVER_UNKNOWN ||
      statusCode === ResponseCode.UNAUTHORIZED) &&
    location.pathname !== navPaths.RELOAD
  )
    return <Navigate to={navPaths.RELOAD} />;

  // navigate to maintenance when fix maintain server
  if (
    statusCode === ResponseCode.SERVER_MAINTAIN &&
    location.pathname !== navPaths.MAINTENANCE
  )
    return <Navigate to={navPaths.MAINTENANCE} />;

  // navigate to register page if dont have acount
  if (
    (!accountV2?.data || !accountV2.data.id) &&
    location.pathname !== navPaths.REGISTER
  )
    return <Navigate to={navPaths.REGISTER} />;

  // navigate to home page if user has acount
  if (
    accountV2?.data && accountV2?.data.id &&
    (location.pathname === navPaths.REGISTER ||
      location.pathname === navPaths.MAINTENANCE ||
      location.pathname === navPaths.RELOAD)
  )
    return <Navigate to="/" />;

  return (
    <Suspense fallback={<LoadingScreen />}>
      <Outlet />
    </Suspense>
  );
};

export default AuthLayout;
