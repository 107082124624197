import { Backdrop, CircularProgress } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Slide, toast, ToastContainer } from "react-toastify";
import ModalContainer from "../../../components/common/ModalContainer";
import { navPaths } from "../../../constants/navbar.constants";
import BaseBtn from "../Components/BaseBtn";
import CreatematchModal from "../Components/Modal/CreateMatchModal";
import JoinRoomModal from "../Components/Modal/JoinRoomModal";

const HomeScreen = () => {
  const navigate = useNavigate();
  const tele = window.Telegram.WebApp;

  tele.BackButton.show();
  tele.BackButton.onClick(() => navigate("/"));

  const [showCreatematchModal, setShowCreatematchModal] =
    useState<boolean>(false);
  const [isOpenJoinModal, setIsOpenJoinModal] = useState<boolean>(false);

  const handleCreateMatch = () => {
    navigate(navPaths.FIND_MATCH);
  };

  const handleJoinRoom = () => {
    navigate(navPaths.FIND_MATCH);
  };
  return (
    <>
      <ToastContainer
        position="top-left"
        closeOnClick
        transition={Slide}
        hideProgressBar
        limit={1}
        stacked
        className="top-3 max-w-[337px] left-[50%] -translate-x-[50%]"
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={false}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div
        className="pt-[42px] px-7 bg-[#F2FFE0] h-screen relative z-30 bg-cover bg-no-repeat bg-center font-baloo-paaji"
        style={{ backgroundImage: `url("/images/battle_bird/background.png")` }}
      >
        {/* <div className="w-screen h-screen backdrop-blur-md bg-[#00000033] absolute z-0 top-0 left-0"></div> */}
        <div className="relative z-10 h-full flex flex-col justify-center pb-[30%] gap-[45px]">
          <div
            className="bg-contain bg-no-repeat bg-center flex-1 max-h-[220px] -mb-[7%]"
            style={{
              backgroundImage: `url("/images/battle_bird/logo.png")`,
            }}
          ></div>
          <div className="flex flex-col w-full gap-8">
            <BaseBtn onClick={() => navigate(navPaths.BATTLE_ONLINE)}>
              Play Online
            </BaseBtn>
            <button
              // onClick={() => setShowCreatematchModal(true)}
              onClick={() => {
                toast.info("Coming soon", {
                  style: { maxWidth: 337, height: 40, borderRadius: 8 },
                  autoClose: 1000,
                  toastId: "coming-soon-game",
                });
              }}
              className="bg-[#B3B3B3] uppercase border-[2.5px] rounded-xl py-2 border-[#606060] shadow-[0_3.5px_0.5px_#5F5F5F] font-baloo-paaji font-bold text-[24px] text-[#515151] pointer-events-none"
            >
              Play With Friends
            </button>

            {/* <BaseBtn onClick={() => navigate(navPaths.PICK_TURN)}>
              Bird Setup
            </BaseBtn> */}
          </div>
        </div>
      </div>
      <ModalContainer>
        {showCreatematchModal && (
          <CreatematchModal
            handleClose={() => setShowCreatematchModal(false)}
            handleCreate={() => handleCreateMatch()}
            openJoinModal={() => setIsOpenJoinModal(true)}
            title={"Play with friend"}
            desciption={"What would you like to do?"}
            type="create"
          />
        )}
      </ModalContainer>
      <ModalContainer>
        {isOpenJoinModal && (
          <JoinRoomModal
            handleClose={() => setIsOpenJoinModal(false)}
            handleJoin={() => handleJoinRoom()}
          />
        )}
      </ModalContainer>
    </>
  );
};

export default HomeScreen;
