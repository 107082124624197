import { create } from "zustand";

export const useBirdBattle = create((set) => ({
  roomType: null,
  room: null,
  match: null,
  lineup: [],
  endgame: null,
  randomHitExclude: [],
  yourTurn: null,
  updateYourTurn: (yourTurn: any) => set({ yourTurn: yourTurn }),
  updateRandomHitExclude: (randomHitExclude: any) =>
    set({ randomHitExclude: randomHitExclude }),
  updateRoomType: (roomType: string) => set({ roomType: roomType }),
  updateRoom: (room: any) => set({ room: room }),
  updateMatch: (match: any) => set({ match: match }),
  updateLineup: (lineup: any) => set({ lineup: lineup }),
  updateEndgame: (endgame: any) => set({ endgame: endgame }),
}));
