import clsx from "clsx";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import Countdown from "react-countdown";
import { isMobile } from "react-device-detect";
import { useBattleTut } from "../../../../store/useBattleTut";

const GameCountDown = forwardRef(
  (
    {
      gameReady,
      yourTurn,
      onCompleteTime,
      opponentName,
    }: {
      gameReady: boolean;
      yourTurn: boolean;
      onCompleteTime: () => void;
      opponentName: string;
    },
    ref,
  ) => {
    const [time, setTime] = useState<any>(Date.now() + 10000);
    const firstTime = useBattleTut((state: any) => state.firstTime);

    useImperativeHandle(ref, () => ({
      restartFromParent() {
        restartCountDown();
      },
    }));

    // const [key, setKey] = useState<any>(false);
    const coundownRef = useRef<any>(null);

    const restartCountDown = () => {
      setTime(Date.now() + 20000);
      coundownRef.current.start();
    };

    const handleOnCompleteTime = () => {
      if (gameReady && firstTime) {
        return;
      }
      // setKey((i: any) => !i);
      restartCountDown();
      onCompleteTime();
    };

    useEffect(() => {
      if (gameReady && firstTime) {
        return;
      }
      restartCountDown();
    }, [firstTime, gameReady, yourTurn]);

    return (
      <Countdown
        ref={coundownRef}
        // key={key}
        date={time}
        renderer={(props) => {
          return (
            <div
              className={clsx(
                "border rounded-lg flex items-center justify-center px-3",
                // yourTurn ? "border-white/50" : "border-[#ff8b8b]",
                "border-white/50",
                isMobile ? "mb-8 py-[6px]" : "mb-3 py-1 -mt-1",
              )}
            >
              <div className="text-base">
                <span>{!gameReady && "Time Remaining"}</span>
                <span>{gameReady && yourTurn && "Your Turn"}</span>

                {gameReady && !yourTurn && (
                  <>
                    <span className="capitalize">{opponentName}</span>
                    <span>'s Turn</span>
                  </>
                )}

                {!gameReady && firstTime && (
                  <span>: {props.formatted.seconds}s</span>
                )}
                {!firstTime && <span>: {props.formatted.seconds}s</span>}
              </div>
            </div>
          );
        }}
        onComplete={handleOnCompleteTime}
      />
    );
  },
);

export default GameCountDown;
