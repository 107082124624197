import clsx from "clsx";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
// import { navPaths } from "../../../../constants/navbar.constants";
import { useAccountV2 } from "../../../../components/Hooks/useRegisterV2";
import { useBirdBattle } from "../../../../store/useBirdBattle";
import { sleep } from "../../../../utils/time";
import PlayerItem from "../PlayerItem";
import StopWatch from "./StopWatch";

const PublicMatchMaking = ({
  socketUrl,
  setMatchMaking,
  handleChangeSocketBuildLineupUrl,
  sendMessage,
  lastMessage,
  connectionStatus,
  telegramID,
}: {
  socketUrl: string;
  setMatchMaking: Dispatch<SetStateAction<boolean>>;
  handleChangeSocketBuildLineupUrl: (arg0: any, arg1: any) => void;
  sendMessage: any;
  lastMessage: any;
  connectionStatus: any;
  telegramID: string;
}) => {
  const navigate = useNavigate();
  const { accountV2 } = useAccountV2();
  // const tele = window.Telegram.WebApp;
  // const telegramID = tele?.initData?.replaceAll("&", "%26");

  const roomType = useBirdBattle((state: any) => state.roomType);
  const updateRoom = useBirdBattle((state: any) => state.updateRoom);
  const updateMatch = useBirdBattle((state: any) => state.updateMatch);
  const room = useBirdBattle((state: any) => state.room);
  const match = useBirdBattle((state: any) => state.match);

  const WEBSOCKET_URL = process.env.REACT_APP_SOCKET_URL;
  const SOCKET_URL_MATCH_MAKING = `${WEBSOCKET_URL}/battle-seed/find-match?telegram_data=${telegramID}`;

  // const [socketUrl] = useState(SOCKET_URL);

  // const handleCloseSocket = () => setSocketUrl("wss://demos.kaazing.com/echo");

  // const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl);

  const handleSendMessage = useCallback(
    (message: string) => sendMessage(message),
    [sendMessage],
  );

  // const connectionStatus = {
  //   [ReadyState.CONNECTING]: "Connecting",
  //   [ReadyState.OPEN]: "Open",
  //   [ReadyState.CLOSING]: "Closing",
  //   [ReadyState.CLOSED]: "Closed",
  //   [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  // }[readyState];

  const lastMessageData = useMemo(() => {
    if (lastMessage?.data) {
      return JSON.parse(lastMessage?.data);
    } else {
      return null;
    }
  }, [lastMessage?.data]);

  const [gameFound, setGameFound] = useState<boolean>(false);

  useEffect(() => {
    if (lastMessage !== null) {
      if (lastMessageData?.error) {
        if (
          accountV2?.data?.id === lastMessageData?.data?.user_id &&
          socketUrl === SOCKET_URL_MATCH_MAKING
        ) {
          toast.error(lastMessageData?.error, {
            style: {
              maxWidth: 337,
              height: 40,
              borderRadius: 8,
              textTransform: "capitalize",
            },
            autoClose: 2000,
          });
          setTimeout(() => {
            navigate("/battle-online");
          }, 1000);
          return;
        }
      }
      // Todo: handle match found
      // updateMatchId(JSON.parse(lastMessage.data).match_id);
      const match = lastMessageData?.match;
      const room = lastMessageData?.room;

      if (
        (accountV2?.data?.id === match?.red_user_id ||
          accountV2?.data?.id === match?.blue_user_id) &&
        room &&
        match
      ) {
        updateRoom(room);
        updateMatch(match);
        handleChangeSocketBuildLineupUrl(room, match);
      }
      // if (match && room) {
      //   handleCloseSocket();
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    lastMessage,
    lastMessageData?.error,
    lastMessageData?.match,
    lastMessageData?.room,
    lastMessageData?.user_id,
    navigate,
    updateMatch,
    updateRoom,
  ]);

  console.log("PublicMatchMaking connectionStatus", connectionStatus);

  const firstLoad = useRef(true);
  useEffect(() => {
    if (firstLoad.current) {
      handleSendMessage(
        JSON.stringify({
          room_type_id: roomType?.id,
        }),
      );
      firstLoad.current = false;
      return;
    }
  }, [handleSendMessage, roomType?.id]);

  useEffect(() => {
    if (match?.red_user_id && match?.blue_user_id) {
      setGameFound(
        accountV2?.data?.id === match?.red_user_id ||
          accountV2?.data?.id === match?.blue_user_id,
      );
    }
  }, [accountV2?.data?.id, match?.blue_user_id, match?.red_user_id]);

  const [countdown, setCountdown] = useState(3);

  useEffect(() => {
    const startCountdown = async () => {
      for (let i = 0; i < 5; i++) {
        await sleep(1000);
        setCountdown((countdown) => countdown - 1);
        if (i === 4) {
          // navigate(navPaths.DEPLOY_BIRD);
          setMatchMaking(false);
        }
      }
    };
    if (gameFound) {
      setCountdown(3);
      startCountdown();
    }
  }, [gameFound, navigate, setMatchMaking]);

  return (
    <>
      {match && room && gameFound ? (
        <>
          <div className="h-full flex flex-col items-center justify-evenly relative z-10">
            {/* <div className="mb-6 text-center text-[#fff] w-[272px] invisible">
              <p className="text-[40px] font-black font-baloo-paaji text-baloo-border drop-shadow-[0_2px_2px_#053C00]">
                Join Room
              </p>
              <p>
                This playroom was created by
                <br />
                your friends
              </p>
            </div> */}
            <div className="invisible">1</div>
            <div className="w-full flex flex-col items-center">
              <PlayerItem
                player={{
                  ...room,
                  username: room?.red_user_name,
                  win: match?.red_wins,
                  lose: match?.red_losses,
                }}
              />
              <div
                className="bg-cover bg-no-repeat bg-center size-[168px] max-h-[168px]"
                style={{
                  backgroundImage: `url("/images/battle_bird/vs.png")`,
                }}
              ></div>
              <PlayerItem
                player={{
                  ...room,
                  username: room?.blue_user_name,
                  win: match?.blue_wins,
                  lose: match?.blue_losses,
                }}
              />
            </div>
            {/* <div className="flex flex-col items-center gap-4">
              <div className="max-w-[140px]">
                <BaseBtn onClick={() => navigate(navPaths.DEPLOY_BIRD)}>
                  <div className="flex items-center justify-center py-1 px-6">
                    Play
                    <div className="ml-3">
                      <svg
                        width="21"
                        height="24"
                        viewBox="0 0 21 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g filter="url(#filter0_d_8864_1410)">
                          <mask
                            id="path-1-outside-1_8864_1410"
                            maskUnits="userSpaceOnUse"
                            x="1.82812"
                            y="-0.69043"
                            width="18"
                            height="21"
                            fill="black"
                          >
                            <rect
                              fill="white"
                              x="1.82812"
                              y="-0.69043"
                              width="18"
                              height="21"
                            />
                            <path d="M16.9297 8.20801C17.5026 8.59863 17.8021 9.13249 17.8281 9.80957C17.8021 10.5127 17.5026 11.0335 16.9297 11.3721L5.67969 18.2471C5.05469 18.6377 4.42969 18.6637 3.80469 18.3252C3.17969 17.9606 2.85417 17.4137 2.82812 16.6846V2.93457C2.85417 2.2054 3.17969 1.65853 3.80469 1.29395C4.42969 0.955404 5.05469 0.968424 5.67969 1.33301L16.9297 8.20801Z" />
                          </mask>
                          <path
                            d="M16.9297 8.20801C17.5026 8.59863 17.8021 9.13249 17.8281 9.80957C17.8021 10.5127 17.5026 11.0335 16.9297 11.3721L5.67969 18.2471C5.05469 18.6377 4.42969 18.6637 3.80469 18.3252C3.17969 17.9606 2.85417 17.4137 2.82812 16.6846V2.93457C2.85417 2.2054 3.17969 1.65853 3.80469 1.29395C4.42969 0.955404 5.05469 0.968424 5.67969 1.33301L16.9297 8.20801Z"
                            fill="white"
                          />
                          <path
                            d="M16.9297 8.20801L17.493 7.38178L17.4724 7.36773L17.4511 7.35473L16.9297 8.20801ZM17.8281 9.80957L18.8274 9.84658L18.8288 9.80886L18.8274 9.77114L17.8281 9.80957ZM16.9297 11.3721L16.421 10.5111L16.4146 10.5149L16.4082 10.5188L16.9297 11.3721ZM5.67969 18.2471L5.15821 17.3937L5.14969 17.3991L5.67969 18.2471ZM3.80469 18.3252L3.30082 19.189L3.31449 19.1969L3.3284 19.2045L3.80469 18.3252ZM2.82812 16.6846H1.82812V16.7024L1.82876 16.7203L2.82812 16.6846ZM2.82812 2.93457L1.82876 2.89888L1.82812 2.91672V2.93457H2.82812ZM3.80469 1.29395L3.3284 0.414653L3.31449 0.422192L3.30082 0.430166L3.80469 1.29395ZM5.67969 1.33301L6.20114 0.479726L6.1924 0.474387L6.18356 0.469229L5.67969 1.33301ZM16.3664 9.03424C16.6845 9.25115 16.8153 9.49642 16.8289 9.848L18.8274 9.77114C18.7888 8.76856 18.3207 7.94611 17.493 7.38178L16.3664 9.03424ZM16.8288 9.77256C16.8145 10.1589 16.6811 10.3574 16.421 10.5111L17.4384 12.233C18.3241 11.7096 18.7897 10.8665 18.8274 9.84658L16.8288 9.77256ZM16.4082 10.5188L5.15824 17.3938L6.20114 19.1004L17.4511 12.2254L16.4082 10.5188ZM5.14969 17.3991C4.81609 17.6076 4.57518 17.6053 4.28097 17.4459L3.3284 19.2045C4.28419 19.7222 5.29329 19.6678 6.20969 19.0951L5.14969 17.3991ZM4.30856 17.4614C3.98281 17.2714 3.84141 17.0388 3.82749 16.6489L1.82876 16.7203C1.86692 17.7887 2.37657 18.6498 3.30082 19.189L4.30856 17.4614ZM3.82812 16.6846V2.93457H1.82812V16.6846H3.82812ZM3.82749 2.97026C3.84141 2.58032 3.98281 2.34774 4.30856 2.15772L3.30082 0.430166C2.37657 0.969313 1.86692 1.83049 1.82876 2.89888L3.82749 2.97026ZM4.28097 2.17324C4.59225 2.00463 4.84578 2.00426 5.17582 2.19679L6.18356 0.469229C5.2636 -0.0674155 4.26713 -0.0938209 3.3284 0.414653L4.28097 2.17324ZM5.15824 2.18629L16.4082 9.06129L17.4511 7.35473L6.20114 0.479726L5.15824 2.18629Z"
                            fill="#0B8B00"
                            mask="url(#path-1-outside-1_8864_1410)"
                          />
                        </g>
                        <defs>
                          <filter
                            id="filter0_d_8864_1410"
                            x="0.288125"
                            y="0.0241328"
                            width="20.08"
                            height="23.1269"
                            filterUnits="userSpaceOnUse"
                            color-interpolation-filters="sRGB"
                          >
                            <feFlood
                              flood-opacity="0"
                              result="BackgroundImageFix"
                            />
                            <feColorMatrix
                              in="SourceAlpha"
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                              result="hardAlpha"
                            />
                            <feOffset dy="1.778" />
                            <feGaussianBlur stdDeviation="1.27" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix
                              type="matrix"
                              values="0 0 0 0 0.0187383 0 0 0 0 0.236784 0 0 0 0 0 0 0 0 1 0"
                            />
                            <feBlend
                              mode="normal"
                              in2="BackgroundImageFix"
                              result="effect1_dropShadow_8864_1410"
                            />
                            <feBlend
                              mode="normal"
                              in="SourceGraphic"
                              in2="effect1_dropShadow_8864_1410"
                              result="shape"
                            />
                          </filter>
                        </defs>
                      </svg>
                    </div>
                  </div>
                </BaseBtn>
              </div>

              <p className="text-sm text-white">
                Click "Play" when both are ready.
              </p>
            </div> */}
            <div
              className={clsx(
                "font-baloo-paaji uppercase text-[36px] drop-shadow-[0_2px_2px_#053C00] text-white font-bold",
                countdown >= 0 ? "animate-bounce" : "animate-ping",
              )}
            >
              {countdown < 0 ? "Ready" : countdown}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="h-full flex flex-col items-center justify-center gap-3 relative z-10">
            <p className="font-baloo-paaji uppercase text-[32px] drop-shadow-[0_2px_2px_#053C00] text-white font-bold">
              Matchmaking
            </p>
            {/* <div className="battle_loader"></div> */}
            <div className="game-loader mt-3"></div>
            <StopWatch />
          </div>
        </>
      )}
    </>
  );
};

export default PublicMatchMaking;
