import clsx from "clsx";

const PlayerItem = ({ player }: { player?: any }) => {
  return (
    <div
      className={clsx(
        "border-[3px] bprder-[#FFFFFF] rounded-xl bg-[#0000004D] py-[21px] px-4 w-full",
        "flex gap-4 items-center",
      )}
    >
      <div
        className={clsx(
          "size-[60px] rounded-[50%] border-[2px] border-[#FFFFFF33] overflow-hidden",
          "flex items-center justify-center",
        )}
      >
        {player ? (
          <img
            src={`https://konsen-image-proxy.taskfi.io/proxy/${player?.username}`}
            alt=""
          ></img>
        ) : (
          <img src="/images/battle_bird/player-placeholder.png" alt=""></img>
        )}
      </div>
      <div className="flex-1">
        <p className="text-[22px] text-[#fff] text-baloo-border font-bold font-baloo-paaji line-clamp-1">
          {player ? player?.username : "- - - - -"}
        </p>
        <div className="flex items-center justify-between font-medium text-[#fff]">
          <p>Won: {player?.win ? player?.win : "---"}</p>
          <p>|</p>
          <p>Lost: {player?.lose ? player?.lose : "---"}</p>
        </div>
      </div>
    </div>
  );
};

export default PlayerItem;
