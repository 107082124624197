import clsx from "clsx";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useAccountV2 } from "../../../components/Hooks/useRegisterV2";
import { navPaths } from "../../../constants/navbar.constants";
import { useBirdBattle } from "../../../store/useBirdBattle";
import "../BattleBird.css";
import BaseBtn from "../Components/BaseBtn";

const EndGame = () => {
  const tele = window.Telegram.WebApp;
  tele.BackButton.show();
  tele.BackButton.onClick(() => {
    navigate(navPaths.BATTLE_HOME);
  });

  const endgame = useBirdBattle((state: any) => state.endgame);
  const { accountV2 } = useAccountV2();

  const navigate = useNavigate();

  const win = useMemo(() => {
    if (accountV2?.data?.id === endgame.winner) {
      return true;
    }
    if (accountV2?.data?.id === endgame.loser) {
      return false;
    }
    return null;
  }, [accountV2?.data?.id, endgame]);

  const handleEndgame = () => {
    navigate(navPaths.BATTLE_HOME);
  };

  return (
    <div className="text-white bg-[#F2FFE0] h-screen relative z-30 font-baloo-paaji flex flex-col items-center justify-center">
      <div className="w-screen h-screen backdrop-blur-md bg-[#000000F0] absolute z-0 top-0 left-0"></div>
      <div
        className={clsx(
          "w-full aspect-square z-10 absolute bottom-0 -mb-[60%] blur-3xl",
          win ? "endgame-victory" : "endgame-defeat",
        )}
      ></div>
      {win && (
        <div className="z-20 flex flex-col items-center justify-center">
          <img
            src="/images/battle_bird/endgame-victory.png"
            alt="victory"
            className="w-[70%] object-contain"
          />
          <div className="p-5 flex flex-col items-center justify-center gap-6 bg-[#FFFFFF0A] w-[75%] rounded-2xl">
            <span className="font-baloo-paaji text-baloo-border font-bold text-[24px]">
              Your Reward
            </span>
            <div className="flex items-center gap-4">
              <div className="flex flex-col items-center gap-1">
                <div className="w-[72px] aspect-square border-[3px] border-[#68C022] bg-[#7BE52729] rounded-xl p-2">
                  <img
                    src="/images/battle_bird/chest.png"
                    alt=""
                    className="w-full aspect-square object-contain"
                  />
                </div>
                <div className="font-baloo-paaji text-[18px] font-bold flex items-center gap-1">
                  +{endgame?.amount / 10 ** 9}{" "}
                  <img
                    src="/images/battle_bird/seed.png"
                    alt=""
                    className="w-4 aspect-square object-contain"
                  />
                </div>
              </div>

              {endgame?.egg_piece_type && (
                <div className="flex flex-col items-center gap-1">
                  <div className="w-[72px] aspect-square border-[3px] border-[#FFBC39] bg-[#FFBC3929] rounded-xl p-2">
                    <img
                      src={`images/lucky_spin/${endgame?.egg_piece_type}.png`}
                      alt=""
                      className="w-full aspect-square object-contain flex-none"
                    />
                  </div>
                  <div className="font-baloo-paaji text-[18px] font-bold">
                    +1
                  </div>
                </div>
              )}
            </div>
            {endgame?.opponentRetreat && (
              <span className="text-sm">Your opponent left the match.</span>
            )}
          </div>
          <div className="w-[150px] mt-[10%]">
            <BaseBtn onClick={handleEndgame}>CLAIM</BaseBtn>
          </div>
        </div>
      )}

      {!win && (
        <div className="z-20 flex flex-col items-center justify-center mb-[30%]">
          <img
            src="/images/battle_bird/endgame-defeat.png"
            alt="victory"
            className="w-[70%] object-contain"
          />
          <div className="p-5 flex flex-col items-center justify-center gap-6 bg-[#FFFFFF0A] rounded-[20px] w-[75%]">
            <span className="text-sm text-[#7D7D7D]">
              Nice try! Want to play again?
            </span>
          </div>
          <div className="w-[150px] mt-[10%]">
            <BaseBtn onClick={handleEndgame} className="bg-white">
              TRY AGAIN
            </BaseBtn>
          </div>
        </div>
      )}
    </div>
  );
};

export default EndGame;
